/*
Copyright 2015, 2019 Google Inc. All Rights Reserved.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 cf. https://googlechrome.github.io/samples/service-worker/custom-offline-page/
 cf. https://developers.google.com/web/fundamentals/primers/service-workers/
*/

/* eslint-disable-next-line no-restricted-globals */
const ignored = self.__WB_MANIFEST;

// Incrementing OFFLINE_VERSION will kick off the install event and force
// previously cached resources to be updated from the network.
const OFFLINE_VERSION = 1.6
const CACHE_NAME = 'hoomy-cache-v'+OFFLINE_VERSION
const OFFLINE_URL = 'offline.html'
const OFFLINE_IMG = 'images/offline.svg'
var CACHE_CONTENT = [
  // '/__hoomy/index.html',
  // '/__hoomy/app.js',
  // '/__hoomy/style.css',
  'offline.html',
  'images/favicon.ico',
  'images/hoomy-192.png',
  'images/hoomy-512.png',
  'images/offline.svg',
  'images/logo-hoomy.svg',
  'images/icons.svg'
]

// https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
/* eslint-disable-next-line no-restricted-globals */
self.addEventListener('install', (event) => {
  event.waitUntil((async () => {
    // const cache = await caches.open(CACHE_NAME)
    const cache = await caches.open(CACHE_NAME).then((cache) => {
      console.log('Mise en cache globale: app shell et contenu')
      return cache.addAll(CACHE_CONTENT) //, {cache: 'reload'})
    })
    // const cache = await caches.open(CACHE_NAME).then((cache) => {
    //   console.log('Mise en cache globale: app shell et contenu')
    //   return cache.add(new Request(OFFLINE_URL, {cache: 'reload'}))
    // })

    // Setting {cache: 'reload'} in the new request will ensure that the response
    // isn't fulfilled from the HTTP cache; i.e., it will be from the network.
    // await cache.add(new Request(OFFLINE_URL, {cache: 'reload'}))
  })())
})

/* eslint-disable-next-line no-restricted-globals */
self.addEventListener('activate', (event) => {
  event.waitUntil((async () => {
    // Enable navigation preload if it's supported.
    // See https://developers.google.com/web/updates/2017/02/navigation-preload
    /* eslint-disable-next-line no-restricted-globals */
    if ('navigationPreload' in self.registration) {
      /* eslint-disable-next-line no-restricted-globals */
      await self.registration.navigationPreload.enable()
    }
  })())

  // Tell the active service worker to take control of the page immediately.
  /* eslint-disable-next-line no-restricted-globals */
  self.clients.claim()
})

/* eslint-disable-next-line no-restricted-globals */
self.addEventListener('fetch', (event) => {
  // We only want to call event.respondWith() if this is a navigation request
  // for an HTML page.
  event.respondWith((async () => {
    try {
      // First, try to use the navigation preload response if it's supported.
      const preloadResponse = await event.preloadResponse
      if (preloadResponse) {
        return preloadResponse
      }
      return await fetch(event.request)

    } catch (error) {
      // catch is only triggered if an exception is thrown, which is likely
      // due to a network error.
      // If fetch() returns a valid HTTP response with a response code in
      // the 4xx or 5xx range, the catch() will NOT be called.
      // console.log('Fetch failed; returning offline page instead.', error)
      const cache = await caches.open(CACHE_NAME)
      console.log(event.request)
      if (event.request.mode === 'navigate') {
        return await cache.match(OFFLINE_URL)
      }
      else if (event.request.destination === 'image') {
        return await cache.match(OFFLINE_IMG)
      }
    }
  })())

  // If our if() condition is false, then this fetch handler won't intercept the
  // request. If there are any other fetch handlers registered, they will get a
  // chance to call event.respondWith(). If no fetch handlers call
  // event.respondWith(), the request will be handled by the browser as if there
  // were no service worker involvement.
})
